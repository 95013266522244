import React, { useEffect, useState, useRef } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { doLogin, doLoginFirebase, firebaseCurrentUser } from '../../services/AuthService'
//import app from '../../config/firebase';
//import 'firebase/auth';
//import { app, auth, db } from "../../config/firebase";
//import { signInWithEmailAndPassword } from "firebase/auth";
//import { ref, onValue } from 'firebase/database';
//import { ref, uploadBytes, uploadString } from "firebase/storage";
//import { getFirestore, collection, addDoc } from "firebase/firestore";

//import { useSelector, useDispatch } from 'react-redux';



function GloveIndex(){

    const history = useHistory();

    useEffect(() => {

    }, [])

    return (        
            <main>                
                <section className="vh-lg-100 mt-5 mt-lg-0 bg-soft d-flex align-items-center">
                    {/* { useSelector(state => state.usuarioLogado) > 0 ? <Redirect to='/settings' /> : null } */}
                    <div className="container">                        
                        <div className="col-12 d-flex align-items-center justify-content-center">
                            <div className="bg-white shadow border-0 rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                                <div className="text-center">                                    
                                    <img src="/img/favicon/logo_gospellove.png" alt="Gospel Love" width={150} />
                                </div>                                
                            </div>
                        </div>
                    </div>
                </section>
            </main>        
    )

}

export default GloveIndex;