import axios from 'axios';
//import FirebaseService, { firebaseSignInWithEmailAndPassword } from './FirebaseService';
//import { auth } from './FirebaseService';

import { app } from './FirebaseService';
import { getAuth, signInWithEmailAndPassword, signOut, onAuthStateChanged  } from "firebase/auth";

import { useSelector, useDispatch } from 'react-redux';


const API_URL = process.env.REACT_APP_API_URL;
//const firebaseAuth = auth;
const auth = getAuth(app);


onAuthStateChanged(auth, (user) => {
  if (user) {    
    const uid = user.uid;    
  } else {
      const uid = null;    
  }
});

export async function doLogin(email, password) {

    //const loginUrl = `${API_URL}/login`;
    //const response = await axios.post(loginUrl, {email, password});
    //console.log(response.data);
    //return response.data;    

    return new Promise((response, reject) => {    
        if(email === 'stefano.angelidis@gmail.com' && password === '123456') {
            response(true);
        }
        reject(`Invalid user and/or password!`);
    })

}

export async function doLoginFirebase(email, password) {

    console.log(`AuthService - doLoginFirebase()`); 
    
    return new Promise((response, reject) => {        
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {            
            const user = userCredential.user;
            console.log('AuthService - doLoginFirebase() - Success' + user.uid);            
            response(user);
        })
        .catch((error) => {
            console.log(`AuthService - doLoginFirebase() - Error`);
            const errorCode = error.code;
            const errorMessage = error.message;            
            console.log(errorMessage);
            reject(errorMessage);
        });        
    });
}

export async function doLogout(token) {
    const logoutUrl = `${API_URL}/logout`;
    const headers = { 'authorization': token, 'glauth': 'gl01' };
    
    const response = await axios.post(logoutUrl, {}, { headers });
    return response.data;
}

export async function doLogoutFirebase() {

   return new Promise((response, reject) => {        
        auth.signOut()
        .then(() => {
            console.log('AuthService - doLogoutFirebase() - Success');
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            const dispatch = useDispatch();
            dispatch({type: 'LOG_OUT', usuarioEmail: null});
            response(true);
        })
        .catch((error) => {
            console.log('AuthService - doLogoutFirebase() - Error');
            const errorCode = error.code;
            const errorMessage = error.message;            
            console.log(errorMessage);
            reject(errorMessage);
        });        
    });
}

export function firebaseCurrentUser() {
    console.log('AuthService - firebaseCurrentUser(): ');

    const user = auth.currentUser;
    if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        // ...
    } else {
        // No user is signed in.
    }

    return user;

    // return new Promise((response, reject) => {        
    //     auth.currentUser()
    //     .then(() => {            
    //         response(auth.currentUser());
    //     })
    //     .catch((error) => {            
    //         const errorCode = error.code;
    //         const errorMessage = error.message;            
    //         console.log(errorMessage);
    //         reject(errorMessage);
    //     });        
    // });    
}