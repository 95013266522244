import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export async function alterarSenhaPorEmail(email, verificationCode, password, passwordConfirm, language) {

    const apiUrl = `${API_URL}/alterarSenhaPorEmail`;
    console.log("apiUrl: " + apiUrl);
    //const headers = { 'glauth': 'gl01' };
    
    let config = {
      headers: {
        glauth: "gl01",
      },
    };

    const response = await axios.post(apiUrl, { email, verificationCode, password, passwordConfirm, lang:language }, config);
    console.log(response.data);
    return response.data;
}