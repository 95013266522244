import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export async function validarEmail(email, verificationCode, language) {

    const apiUrl = `${API_URL}/validarEmail`;
    console.log("apiUrl: " + apiUrl);
    //const response = await axios.get(settingsUrl, { headers });    
    //const headers = { 'Access-Control-Allow-Origin': '*', 'glauth': 'gl01' };

    let config = {
      headers: {        
        "glauth": "gl01",
      },
    };

    //const response = await axios.get(settingsUrl, { headers });    
    //const response = await axios.post(apiUrl, { email, verificationCode, lang:language }, headers);
    const response = await axios.post(apiUrl, { email, verificationCode, lang:language }, config);
    console.log(response.data);
    return response.data;
}