import { values } from '../../shared/strings/strings';

function getLanguage(language) {
    if(language.startsWith('en')) {
        return 'en_US';
    }
    return 'pt_BR';
}

function getLanguageIndex(language) {
    //if(language === 'en' || language === 'en-US' || language === 'en_US') {
    if(language.startsWith('en')) {
        return 1;
    }
    return 0;
}

function getErrorMessageByErrorCode(errorCode, userLanguageIndex) {    
    return values['ERRO_' + errorCode][userLanguageIndex];    
}

function newPasswordValidation(password, passwordConfirm, userLanguageIndex) {
    //const languageIndex = getLanguageIndex(userLang);
    if(!password || password.length < 6) {
        return { valid: false, error: values.ERRO_SENHA_PEQUENA[userLanguageIndex] };
    }

    if(passwordConfirm !== password) {
        return { valid: false, error: values.ERRO_SENHAS_NAO_CONFEREM[userLanguageIndex] };
    }

    return { valid: true, error: '' };
}

// module.exports = {
//     getLanguageIndex,
//     newPasswordValidation
// }

export { getLanguage, getLanguageIndex, getErrorMessageByErrorCode, newPasswordValidation };