const languages = {
    pt_BR: 0,
    en_US: 1,
}

const values = {
    RECUPERAR_SENHA: ['Recuperar Senha', 'Reset password'],
    SEU_EMAIL: ['E-mail', 'Your Email'],
    NOVA_SENHA: ['Nova Senha', 'New Password'],
    CONFIRME_NOVA_SENHA: ['Confirme a Nova Senha', 'Confirm your New Password'],
    ATUALIZAR_SENHA: ['Atualizar a Senha', 'Update Password'],
    ERRO_SENHA_PEQUENA: ['Senha deve ter ao menos 6 dígitos', 'Password must have at least 6 characters'],
    ERRO_SENHAS_NAO_CONFEREM: ['Senhas não conferem', 'Passwords does not match'],
    ERRO_1000: ['Parâmetro não localizado.', 'Parameter not found.'],
    ERRO_1001: ['Código de Verificação Inválido.', 'Invalid Verification Code.'],
    SEU_EMAIL: ['E-mail', 'Your Email'],
    VALIDAR_EMAIL: ['Validação de E-mail', 'E-mail Validation'],
    VALIDAR: ['Validar E-mail', 'Validate'],
}

export { languages, values };