import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { values } from '../../shared/strings/strings';
import { getLanguage, getLanguageIndex } from '../../shared/utils/utils';
import { validarEmail } from '../../services/EmailValidation';
import parse from 'html-react-parser';

function EmailValidation(){

    const { email, validationCode } = useParams();
    const btnSubmit = useRef('');
    
    const [loading, setLoading] = useState('');
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const [userLanguage, setUserLanguage] = useState('');
    const [userLanguageIndex, setUserLanguageIndex] = useState('');

    useEffect(() => {        
        setLoading('loading');
        const userLang = navigator.language || navigator.userLanguage;
        setUserLanguage(getLanguage(userLang));
        setUserLanguageIndex(getLanguageIndex(userLang));

        validarEmail(email, validationCode, userLanguage)
            .then(response => {                
                if(response.error){                    
                    setLoading('');
                    setError(response.message);                    
                    return;
                }
                setLoading('');
                setError('');
                const message = parse(response.message);
                setSuccess(message);
            })
            .catch(err => {
                setLoading('');
                setError(err);
                //btnSubmit.current.disabled = false;
            }
        );

    }, []);

    return (
        <main>
            <section className="vh-lg-100 mt-5 mt-lg-0 bg-soft d-flex align-items-center">
            <div className="container">
                <div className="row justify-content-center form-bg-image">                    
                    <div className="col-12 d-flex align-items-center justify-content-center">
                        <div className="bg-white shadow border-0 rounded p-4 p-lg-5 w-100 fmxw-500">
                            <div className="text-center">
                                <img src="/img/favicon/logo_gospellove.png" alt="Gospel Love" width={100} />                                
                            </div>
                            <h1 className="h3 text-center mt-4 mb-4">{values.VALIDAR_EMAIL[userLanguageIndex]}</h1>
                            <div className="mb-4">
                                <label htmlFor="email">{values.SEU_EMAIL[userLanguageIndex]}</label>
                                <div className="input-group">
                                    <input type="email" className="form-control" placeholder={email} id="email" required disabled />
                                </div>  
                            </div>                            
                            {
                                loading ?                                
                                <div className="mb-4 text-center">
                                    <div className="text-center">
                                        <img src="/img/icons/loading.gif" alt="Loading" width={40} />                                
                                    </div>                                
                                </div>                           
                                : <React.Fragment></React.Fragment>
                            }
                            {
                                error ?
                                <div className="alert alert-danger mt-2">{error}</div>
                                : <React.Fragment></React.Fragment>
                            }
                            {
                                success ?
                                <div className="alert alert-success mt-2 py-2">{success}</div>
                                : <React.Fragment></React.Fragment>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
    );

}

export default EmailValidation;