import React, { useEffect, useState, useRef } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { doLogin, doLoginFirebase, firebaseCurrentUser } from '../../services/AuthService'
//import app from '../../config/firebase';
//import 'firebase/auth';
//import { app, auth, db } from "../../config/firebase";
//import { signInWithEmailAndPassword } from "firebase/auth";
//import { ref, onValue } from 'firebase/database';
//import { ref, uploadBytes, uploadString } from "firebase/storage";
//import { getFirestore, collection, addDoc } from "firebase/firestore";

//import { useSelector, useDispatch } from 'react-redux';



function Login(){

    const history = useHistory();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    //const [user, setUser] = useState('');

    //const dispatch = useDispatch();

    //const usersRef = ref(db, 'usuarios');  

    //const user = useRef('');

    // function firebaseLogin(){
        
    //     signInWithEmailAndPassword(auth, email, password)
    //         .then(response => {
                
    //             if(response){

    //                 const userRef = ref(db, 'usuarios/' + response.user.uid); 


    //                 onValue(userRef, (snapshot) => {                        
    //                     const data = snapshot.val();

    //                     //alert(data.perfil.idGrupo);                        
                        
    //                     if(data.perfil.idGrupo === 1) {
    //                         setSuccess("Success on Login");
    //                         localStorage.setItem('user', response.user.email);
    //                             dispatch({type: 'LOG_IN', usuarioEmail: email});
    //                         history.push('/settings');
    //                     } else {
    //                         setError('User not Authorized');
    //                     }

    //                 });




    //                 //alert(response.user.email);
    //                 //localStorage.setItem('token', response.token);                    
    //                 // setSuccess("Success on Login");
    //                 // localStorage.setItem('user', response.user.email);
    //                 //     dispatch({type: 'LOG_IN', usuarioEmail: email});
    //                 //     history.push('/settings');
    //                 // setTimeout(() => {
    //                 //     localStorage.setItem('user', response.user.email);
    //                 //     dispatch({type: 'LOG_IN', usuarioEmail: email});
    //                 //     history.push('/settings');
    //                 // }, 2000);                    
    //             }

    //         })
    //         .catch(err => {
    //             const errorMessage = err.message.replace('Firebase: ','');
    //             setError(errorMessage);
    //         })
    // }

    //alert(useSelector(state => state.usuarioEmail));

    useEffect(() => {

        // const user = firebaseCurrentUser();
        // if(user){
        //     setUser("response OK");
        // } else {
        //     setUser("Nenhum Usuário Logado");
        // }

        // firebaseCurrentUser()
        //     .then(response => {            
        //         if(response){
        //             setUser("response");
        //         }                    
        //     })
        //     .catch(err => {
        //         setError(err);
        //     })
        

    }, [])

    function onChangeInput(event){

        //console.log(event.target.value);

        if(event.target.id === 'email') {
            setEmail(event.target.value);
        } else {
            setPassword(event.target.value);
        }

    }

    function onSubmit(event) {
        event.preventDefault();

        doLogin(email, password)
            .then(response => {            
                if(response){
                    localStorage.setItem('token', response.token);
                    history.push('/dashboard');
                }                    
            })
            .catch(err => {
                setError(err);
            })

        //firebaseLogin(email, password);

        // doLoginFirebase(email, password)
        //     .then(response => {            
        //         if(response){
        //             localStorage.setItem('token', response.token);
        //             localStorage.setItem('user', response.email);
        //             history.push('/settings');
        //         }                    
        //     })
        //     .catch(err => {
        //         setError(err);
        //     })
    }

    return (        
            <main>                
                <section className="vh-lg-100 mt-5 mt-lg-0 bg-soft d-flex align-items-center">
                    {/* { useSelector(state => state.usuarioLogado) > 0 ? <Redirect to='/settings' /> : null } */}
                    <div className="container">
                        <p className="text-center">
                            <Link to="/" className="d-flex align-items-center justify-content-center">
                                <svg className="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clipRule="evenodd"></path>
                                </svg>
                                Back to Homepage
                            </Link>
                            {/* <div>{user}</div> */}
                            {/* <h1 className="h6">{useSelector(state => state.usuarioEmail)}</h1> */}
                            {/* <span ref={user}>user</span>                             */}
                        </p>
                        <div className="col-12 d-flex align-items-center justify-content-center">
                            <div className="bg-white shadow border-0 rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                                <div className="text-center">
                                    <img src="/img/favicon/mstile-150x150.png" alt="Beholder" width={64} />
                                </div>
                                <div className="text-center text-md-center mb-4 mt-md-0">
                                    <h1 className="mb-0 h3">Sign in to our platform</h1>
                                </div>
                                <form action="#" className='mt-4' onSubmit={onSubmit}>
                                    <div className="form-group mb-4">
                                        <label htmlFor="email">Your Email</label>
                                        <div className="input-group">
                                            <span className="input-group-text" id="basic-addon1">
                                                <svg className="icon icon-xs text-gray-600" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path><path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path></svg>
                                            </span>
                                            <input type="email" className="form-control" placeholder="example@company.com" id="email" autoFocus required onChange={onChangeInput} />
                                        </div>  
                                    </div>
                                    <div className="form-group">                                    
                                        <div className="form-group mb-4">
                                            <label htmlFor="password">Your Password</label>
                                            <div className="input-group">
                                                <span className="input-group-text" id="basic-addon2">
                                                    <svg className="icon icon-xs text-gray-600" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd"></path></svg>
                                                </span>
                                                <input type="password" placeholder="Password" className="form-control" id="password" required onChange={onChangeInput} />
                                            </div>  
                                        </div>                                    
                                        <div className="d-flex justify-content-between align-items-top mb-4">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="remember" />
                                                <label className="form-check-label mb-0" htmlFor="remember">
                                                Remember me
                                                </label>
                                            </div>
                                            <div><a href="./forgot-password.html" className="small text-right">Lost password?</a></div>
                                        </div>
                                    </div>
                                    <div className="d-grid">
                                        <button type="submit" className="btn btn-gray-800">Sign in</button>
                                    </div>
                                    {
                                        error ?
                                        <div className="alert alert-danger mt-2">{error}</div>
                                        : <React.Fragment></React.Fragment>
                                    }
                                    {
                                        success ?
                                        <div className="alert alert-success mt-2 col-9 py-2">{success}</div>
                                        : <React.Fragment></React.Fragment>
                                    }
                                </form>                                
                            </div>
                        </div>
                    </div>
                </section>
            </main>        
    )

}

export default Login;