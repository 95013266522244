//import firebase from 'firebase';
import { initializeApp } from 'firebase/app';
//import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
//import { getDatabase } from 'firebase/database';


//import * as firebase from 'firebase/app';
//import 'firebase/auth';
//import 'firebase/database';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

//const app = initializeApp(firebaseConfig);
//export default app;

export const app = initializeApp(firebaseConfig);


//const auth = getAuth(app);

// export async function firebaseSignInWithEmailAndPassword(email, password) {    

//     signInWithEmailAndPassword(auth, email, password)
//         .then((userCredential) => {
//             // Signed in
//             const user = userCredential.user;
//             console.log(`AuthService - doLoginFirebase() - Success`);
//             console.log(user.email);
//             //response(true);
//             return user;
//         })
//         .catch((error) => {
//             const errorCode = error.code;
//             const errorMessage = error.message;
//             console.log(`AuthService - doLoginFirebase() - Error`);
//             console.log(errorMessage);
//             return null
//         });
// }

//export const auth = getAuth(app);

//const db = getDatabase(app);

//export const firebaseApp = initializeApp(firebaseConfig);