import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { values } from '../../shared/strings/strings';
import { getLanguage, getLanguageIndex, newPasswordValidation } from '../../shared/utils/utils';
import { alterarSenhaPorEmail } from '../../services/PasswordRecoverService';
import parse from 'html-react-parser';

function PasswordRecover(){

    const { email, validationCode } = useParams();
    const btnSubmit = useRef('');

    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const [userLanguage, setUserLanguage] = useState('');
    const [userLanguageIndex, setUserLanguageIndex] = useState('');

    useEffect(() => {
        const userLang = navigator.language || navigator.userLanguage;
        setUserLanguage(getLanguage(userLang));
        setUserLanguageIndex(getLanguageIndex(userLang));
    }, []);

    
    function onChangeInput(event){
        //console.log(event.target.value);
        if(event.target.id === 'password') {
            setPassword(event.target.value);
        } else {
            setPasswordConfirm(event.target.value);
        }
    }

    function onSubmit(event) {
        event.preventDefault();
        btnSubmit.current.disabled = true;
        const validation = newPasswordValidation(password, passwordConfirm, userLanguageIndex);
        if(validation.error) {
            setError(validation.error);
            btnSubmit.current.disabled = false;
            return;
        }

        setError('');
                
        alterarSenhaPorEmail(email, validationCode, password, passwordConfirm, userLanguage)
            .then(response => {
                //console.log("response: " + response);
                if(response.error){
                    //const errorMessage = getErrorMessageByErrorCode(response.errorCode, userLanguageIndex);                    
                    setError(response.message);
                    btnSubmit.current.disabled = false;
                    return;
                }
                setError('');
                const message = parse(response.message);
                setSuccess(message);
            })
            .catch(err => {
                setError(err);
                btnSubmit.current.disabled = false;
            });
    }

    return (
        <main>
            <section className="vh-lg-100 mt-5 mt-lg-0 bg-soft d-flex align-items-center">
            <div className="container">
                <div className="row justify-content-center form-bg-image">                    
                    <div className="col-12 d-flex align-items-center justify-content-center">
                        <div className="bg-white shadow border-0 rounded p-4 p-lg-5 w-100 fmxw-500">
                            <div className="text-center">
                                <img src="/img/favicon/logo_gospellove.png" alt="Gospel Love" width={100} />                                
                            </div>
                            <h1 className="h3 text-center mt-4 mb-4">{values.RECUPERAR_SENHA[userLanguageIndex]}</h1>
                            <form action="#" className='mt-4' onSubmit={onSubmit}>                                
                                <div className="mb-4">
                                    <label htmlFor="email">{values.SEU_EMAIL[userLanguageIndex]}</label>
                                    <div className="input-group">
                                        <input type="email" className="form-control" placeholder={email} id="email" required disabled />
                                    </div>  
                                </div>
                                <div className="form-group mb-4">
                                    <label htmlFor="password">{values.NOVA_SENHA[userLanguageIndex]}</label>
                                    <div className="input-group">
                                        <span className="input-group-text" id="basic-addon2">
                                            <svg className="icon icon-xs text-gray-600" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd"></path></svg>
                                        </span>
                                        <input type="password" placeholder="" className="form-control" id="password" required onChange={onChangeInput} />
                                    </div>  
                                </div>                                
                                <div className="form-group mb-4">
                                    <label htmlFor="confirm_password">{values.CONFIRME_NOVA_SENHA[userLanguageIndex]}</label>
                                    <div className="input-group">
                                        <span className="input-group-text" id="basic-addon2">
                                            <svg className="icon icon-xs text-gray-600" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd"></path></svg>
                                        </span>
                                        <input type="password" placeholder="" className="form-control" id="confirm_password" required onChange={onChangeInput} />
                                    </div>  
                                </div>                                
                                <div className="d-grid">
                                    <button ref={btnSubmit} type="submit" className="btn btn-gray-800">{values.ATUALIZAR_SENHA[userLanguageIndex]}</button>
                                </div>
                                {
                                    error ?
                                    <div className="alert alert-danger mt-2">{error}</div>
                                    : <React.Fragment></React.Fragment>
                                }
                                {
                                    success ?
                                    <div className="alert alert-success mt-2 py-2">{success}</div>
                                    : <React.Fragment></React.Fragment>
                                }
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
    );

}

export default PasswordRecover;