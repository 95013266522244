import React from "react";
import { Route, BrowserRouter, Redirect } from "react-router-dom";
// import { firebaseCurrentUser } from './services/AuthService';
// import store from './store';
// import { Provider } from "react-redux";
// import { useSelector } from 'react-redux';
import GloveIndex from "./public/GloveIndex/GloveIndex";
import Login from "./public/Login/Login";
import PasswordRecover from "./public/PasswordRecover/PasswordRecover";
import EmailValidation from "./public/EmailValidation/EmailValidation";
// import Settings from "./private/Settings/Settings";
// import Users from "./private/Users/Users";
// import User from "./private/User/User";

function Routes(){

    // function isLoggedIn(){
    //     //return useSelector(state => state.usuarioLogado) > 0 ? true : false;
    // }

    // function PrivateRoute({ children, ...rest }){

    //     return (            
    //         <Route {... rest } render={() => {
                
    //             return localStorage.getItem('user')
    //                 ? children
    //                 : <Redirect to="/" />

    //             // return firebaseCurrentUser
    //             //     ? children
    //             //     : <Redirect to="/" />

    //             // return isLoggedIn()
    //             //         ? children
    //             //         : <Redirect to="/" />
    //         }} />            
    //     )
    // }
    
    // function PrivateRoute({ children, ...rest }) {
    //     return (
    //         <Route {...rest} render={() => {
    //             return localStorage.getItem("token")
    //                 ? children
    //                 : <Redirect to='/' />
    //         }} />
    //     )
    // }

    // return (
    //     <Provider store={store}>
    //         <BrowserRouter>
    //             <Route path="/" exact>
    //                 <Login />
    //             </Route>                
    //             <Route path="/password-recover/:validationCode">
    //                 <PasswordRecover />
    //             </Route>
    //             <PrivateRoute path="/settings">
    //                 <Settings />
    //             </PrivateRoute>
    //             <PrivateRoute path="/user/:id">
    //                 <User />
    //             </PrivateRoute>
    //             <PrivateRoute path="/users">
    //                 <Users />
    //             </PrivateRoute>            
    //         </BrowserRouter>
    //     </Provider>
    // )

    return (
        <BrowserRouter>
            <Route path="/" exact>
                <GloveIndex />
            </Route>
            <Route path="/password-recover/:email/:validationCode">
                <PasswordRecover />
            </Route>
            <Route path="/email-validation/:email/:validationCode">
                <EmailValidation />
            </Route>
            {/* <PrivateRoute path="/settings">
                <Settings />
            </PrivateRoute>
            <PrivateRoute path="/orders/:symbol?">
                <Orders />
            </PrivateRoute>
            <PrivateRoute path="/dashboard">
                <Dashboard />
            </PrivateRoute>
            <PrivateRoute path="/monitors">
                <Monitors />
            </PrivateRoute>
            <PrivateRoute path="/automations">
                <Automations />
            </PrivateRoute> */}
        </BrowserRouter>
    )

}

export default Routes;